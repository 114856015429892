import {
  PowerIcon,
  AcademicCapIcon,
  CalendarIcon,
  ChartBarIcon,
  GiftIcon
} from '@heroicons/react/24/outline';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Payment from '../components/Payment';
import BottomNav from '../components/mobile/BottomNav';
import UserCard from '../components/mobile/UserCard';

function Dashboard() {
  const [searchParams] = useSearchParams();
  const hasPaymentParams = searchParams.get('course') && searchParams.get('price');
  const navigate = useNavigate();

  const cards = [
    { name: 'Активировать аккаунт', icon: PowerIcon, path: '/activate' },
    { name: 'Обучение', icon: AcademicCapIcon, path: '/education' },
    { name: 'Экономический календарь', icon: CalendarIcon, path: '/calendar' },
    { name: 'Трейдинг Центр', icon: ChartBarIcon, path: '/trading' },
    { name: 'Бонусы и акции', icon: GiftIcon, path: '/bonuses' },
  ];

  return (
    <div>
      {hasPaymentParams && (
        <div className="mb-8">
          <Payment />
        </div>
      )}
      <div className="space-y-6">

      <UserCard />
        {/* Cards Grid */}
        <div className="md:grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 hidden">
          {cards.map((card) => (
            <div 
              key={card.name}
              onClick={() => navigate(card.path)}
              className="bg-white rounded-[20px] p-4 md:p-6 flex md:flex-row flex-col items-start md:items-center cursor-pointer 
                       shadow-[0_4px_10px_rgba(0,0,0,0.03)] 
                       hover:shadow-[0_10px_20px_rgba(0,0,0,0.08)] 
                       hover:-translate-y-1 
                       hover:bg-white/95
                       active:translate-y-0
                       active:shadow-[0_5px_10px_rgba(0,0,0,0.05)]
                       transition-all duration-300"
            >
              <div className="bg-primary p-2 rounded-full mr-3">
                <card.icon className="h-8 w-8 text-[#B17A00]" />
              </div>
              <span className="text-black font-bold md:mt-0 mt-2 md:text-[16px] text-[14px]">{card.name}</span>
            </div>
          ))}
        </div>
        <BottomNav />
      </div>
    </div>
  );
}

export default Dashboard; 