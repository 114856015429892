import {
    PowerIcon,
    AcademicCapIcon,
    CalendarIcon,
    ChartBarIcon,
    GiftIcon
  } from '@heroicons/react/24/outline';
  import { useNavigate, useSearchParams, useLocation, Link } from 'react-router-dom';
//   import Payment from '../components/Payment';
  
  
  function BottomNav() {
    const [searchParams] = useSearchParams();
    const hasPaymentParams = searchParams.get('course') && searchParams.get('price');
    const navigate = useNavigate();
    const location = useLocation();
  
    const cards = [
      { name: 'Активировать <br /> аккаунт', icon: PowerIcon, path: '/activate' },
      { name: 'Обучение', icon: AcademicCapIcon, path: '/education' },
      { name: 'Экономический <br /> календарь', icon: CalendarIcon, path: '/calendar' },
      { name: 'Трейдинг <br /> Центр', icon: ChartBarIcon, path: '/trading' },
      { name: 'Бонусы <br /> и акции', icon: GiftIcon, path: '/bonuses' },
    ];
  
    const filteredCards = cards.filter(card => card.path !== location.pathname);
  
    return (
      <div className="md:hidden border-t border-gray-300 mt-8">

        <h2 className='text-xl font-bold mb-4 mt-4'>
            Меню
        </h2>
        <div className="space-y-6">
  
        
          {/* Cards Grid */}
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 md:gap-4 gap-2">
            {filteredCards.map((card) => (
              <Link 
                key={card.name}
                to={card.path}
                className="bg-white rounded-[20px] p-4 md:p-6 flex md:flex-row flex-col items-start md:items-center cursor-pointer 
                         shadow-[0_4px_10px_rgba(0,0,0,0.03)] 
                         hover:shadow-[0_10px_20px_rgba(0,0,0,0.08)] 
                         hover:-translate-y-1 
                         hover:bg-white/95
                         active:translate-y-0
                         active:shadow-[0_5px_10px_rgba(0,0,0,0.05)]
                         transition-all duration-300"
              >
                <div className="bg-primary p-2 rounded-full mr-3">
                  <card.icon className="h-8 w-8 text-[#B17A00]" />
                </div>
                <span className="text-black font-bold md:mt-0 mt-2 md:text-[16px] text-[13px]" dangerouslySetInnerHTML={{ __html: card.name }} />
              </Link>
            ))}
          </div>

          <button className="bg-primary font-bold w-full text-center px-4 py-4 rounded-full">
            Активировать аккаунт
          </button>
        </div>
      </div>
    );
  }
  
  export default BottomNav; 