import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import PageTitle from './PageTitle';

function Layout() {
  const location = useLocation();
  
  const getPageTitle = () => {
    switch(location.pathname) {
      case '/':
        return 'Личный кабинет';
      case '/education':
        return 'Обучение';
      case '/education/video':
        return 'Видеоуроки';
      case '/education/books':
        return 'Электронные книги';
      case '/activate':
        return 'Активация аккаунта';
      case '/calendar':
        return 'Календарь';
      case '/trading':
        return 'Трейдинг центр';
      case '/bonuses':
        return 'Бонусы и акции';
      case '/settings':
        return 'Настройки профиля';
      default:
        return 'Личный кабинет';
    }
  };

  const getBreadcrumbs = () => {
    const crumbs = [];
    switch(location.pathname) {
      case '/':
        crumbs.push({ text: 'Главная', path: '/' });
        break;
      case '/education':
        crumbs.push({ text: 'Главная', path: '/' });
        crumbs.push({ text: 'Обучение', path: '/education' });
        break;
      case '/education/video':
        crumbs.push({ text: 'Главная', path: '/' });
        crumbs.push({ text: 'Обучение', path: '/education' });
        crumbs.push({ text: 'Видеоуроки', path: '/education/video' });
        break;
      case '/education/books':
        crumbs.push({ text: 'Главная', path: '/' });
        crumbs.push({ text: 'Обучение', path: '/education' });
        crumbs.push({ text: 'Электронные книги', path: '/education/books' });
        break;
      case '/activate':
        crumbs.push({ text: 'Главная', path: '/' });
        crumbs.push({ text: 'Активация аккаунта', path: '/activate' });
        break;
      case '/calendar':
        crumbs.push({ text: 'Главная', path: '/' });
        crumbs.push({ text: 'Календарь', path: '/calendar' });
        break;
      case '/trading':
        crumbs.push({ text: 'Главная', path: '/' });
        crumbs.push({ text: 'Трейдинг центр', path: '/trading' });
        break;
      case '/bonuses':
        crumbs.push({ text: 'Главная', path: '/' });
        crumbs.push({ text: 'Бонусы и акции', path: '/bonuses' });
        break;
      case '/settings':
        crumbs.push({ text: 'Главная', path: '/' });
        crumbs.push({ text: 'Настройки профиля', path: '/settings' });
        break;
      default:
        crumbs.push({ text: 'Главная', path: '/' });
    }
    return crumbs;
  };

  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex-1 p-5 md:p-4">
        <PageTitle title={getPageTitle()} breadcrumbs={getBreadcrumbs()} />
        <Outlet />
      </div>
    </div>
  );
}

export default Layout; 