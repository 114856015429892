import React, { createContext, useContext, useState, useEffect } from 'react';

const AdminAuthContext = createContext(null);

export function AdminAuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Проверяем наличие токена при загрузке
    const token = localStorage.getItem('adminToken');
    if (token) {
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, []);

  const login = async (username, password) => {
    try {
      const response = await fetch('https://api.king-academy.net/admin/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка авторизации');
      }

      const data = await response.json();
      localStorage.setItem('adminToken', data.token);
      setIsAuthenticated(true);
      return data;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const logout = () => {
    localStorage.removeItem('adminToken');
    setIsAuthenticated(false);
  };

  const getAuthHeader = () => {
    const token = localStorage.getItem('adminToken');
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  };

  const getUsers = async () => {
    try {
      const response = await fetch('https://api.king-academy.net/admin/user', {
        headers: getAuthHeader(),
      });

      if (!response.ok) {
        throw new Error('Ошибка получения списка пользователей');
      }

      return await response.json();
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const updateUser = async (userId, userData) => {
    try {
      const response = await fetch(`https://api.king-academy.net/admin/user/account/${userId}`, {
        method: 'PUT',
        headers: getAuthHeader(),
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error('Ошибка обновления пользователя');
      }

      return await response.json();
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const deleteUser = async (userId) => {
    try {
      const response = await fetch(`https://api.king-academy.net/admin/user/delete/${userId}`, {
        method: 'DELETE',
        headers: getAuthHeader(),
      });

      if (!response.ok) {
        throw new Error('Ошибка удаления пользователя');
      }

      return await response.json();
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const updateUserBalance = async (userId, balance) => {
    try {
      const response = await fetch(`https://api.king-academy.net/user/${userId}/balance`, {
        method: 'PUT',
        headers: getAuthHeader(),
        body: JSON.stringify({ balance }),
      });

      if (!response.ok) {
        throw new Error('Ошибка обновления баланса');
      }

      return await response.json();
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const updateUserCourse = async (userId, course) => {
    try {
      const response = await fetch(`https://api.king-academy.net/user/${userId}/course`, {
        method: 'PUT',
        headers: getAuthHeader(),
        body: JSON.stringify({ course }),
      });

      if (!response.ok) {
        throw new Error('Ошибка обновления курса');
      }

      return await response.json();
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const createOverdraft = async (userId) => {
    try {
      const response = await fetch(`https://api.king-academy.net/user/overdraft/${userId}`, {
        method: 'POST',
        headers: getAuthHeader(),
      });

      if (!response.ok) {
        throw new Error('Ошибка создания овердрафта');
      }

      return await response.json();
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const value = {
    isAuthenticated,
    isLoading,
    error,
    login,
    logout,
    getAuthHeader,
    getUsers,
    updateUser,
    deleteUser,
    updateUserBalance,
    updateUserCourse,
    createOverdraft,
  };

  return (
    <AdminAuthContext.Provider value={value}>
      {children}
    </AdminAuthContext.Provider>
  );
}

export function useAdminAuth() {
  const context = useContext(AdminAuthContext);
  if (!context) {
    throw new Error('useAdminAuth должен использоваться внутри AdminAuthProvider');
  }
  return context;
} 