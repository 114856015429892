import React from 'react';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import bookCover1 from '../assets/images/book1.png';
import bookCover2 from '../assets/images/book2.png';
import bookCover3 from '../assets/images/book3.png';
import bookCover4 from '../assets/images/book4.png';
import bookCover5 from '../assets/images/book5.png';
import bookCover6 from '../assets/images/book6.png';
import bookCover7 from '../assets/images/book7.png';
import bookCover8 from '../assets/images/book8.png';
import bookCover9 from '../assets/images/book9.png';
import bookCover10 from '../assets/images/book10.png';

import BottomNav from '../components/mobile/BottomNav';


const books = [
  {
    id: 1,
    title: 'Торговля на рынке Форекс',
    description: 'Эта электронная книга была создана с целью дать трейдерам необходимые навыки для онлайн-торговли на рынке Форекс. С помощью комплексной и простой в использовании электронной книги вы вскоре получите достаточно знаний, чтобы начать полноценную карьеру на валютном рынке.',
    image: bookCover1,
    isLocked: true
  },
  {
    id: 2,
    title: 'Торговля акциями',
    description: 'Эта электронная книга была создана с целью дать трейдерам необходимые навыки для торговли акциями в Интернете. C помощью комплексной и простой в освоении электронной книги вы вскоре получите достаточно знаний, чтобы начать полноценную карьеру на фондовом рынке.',
    image: bookCover2,
    isLocked: true
  },
  {
    id: 3,
    title: 'Введение в криптовалюту',
    description: 'В 2008 году, когда все были заняты решением крупнейшего финансового кризиса за последнее время, анонимный программист, писавший под именем Сатоши Накамото, опубликовал статью, в которой предлагалось построить полностью децентрализованную одноранговую цифровую денежную систему.',
    image: bookCover3,
    isLocked: true
  },
  {
    id: 4,
    title: 'Торговля криптовалютой',
    description: 'Эта электронная книга была создана с целью дать трейдерам необходимые навыки для онлайн-торговли криптовалютами. С помощью комплексной и простой в освоении электронной книги вы вскоре получите достаточно знаний, чтобы начать полноценную карьеру на криптовалютном рынке.',
    image: bookCover4,
    isLocked: true
  },
  {
    id: 5,
    title: 'Торговля сырьевыми товарами',
    description: 'В ходе данного курса вы в простой и интерактивной форме изучите все ключевые моменты, которые необходимо знать трейдеру, прежде чем приступать к захватывающей карьере на товарном рынке.',
    image: bookCover5,
    isLocked: true
  },
  {
    id: 6,
    title: 'Торговля CFD',
    description: 'Аббревиатура CFD появляется повсюду в мире онлайн-трейдинга. Аббревиатура расшифровывается как «Контракт на разницу», и причина, по которой она приобрела такую ​​известность, заключается в том, что она сделала возможной модернизацию торговли.',
    image: bookCover6,
    isLocked: true
  },
  {
    id: 7,
    title: 'Психология трейдинга',
    description: 'В этом курсе вы узнаете все о процессе принятия решений в торговле: просто, быстро и интерактивно. В частности, мы обсудим некоторые распространенные психологические препятствия в трейдинге и предложим некоторые решения для них.',
    image: bookCover7,
    isLocked: true
  },
  {
    id: 8,
    title: 'Фундаментальный анализ',
    description: 'Чтобы преуспеть в качестве трейдера, важно, чтобы вы провели подробный анализ рынка, прежде чем открывать какую-либо позицию. Фундаментальный анализ — это тип анализа рынка, который включает изучение экономических, социальных и политических факторов и их влияние на стоимость активов.',
    image: bookCover8,
    isLocked: true
  },
  {
    id: 9,
    title: 'Технический анализ',
    description: 'Чтобы преуспеть в качестве трейдера, важно провести подробный анализ рынка, прежде чем открывать какую-либо позицию. Технический анализ — это стратегия прогнозирования будущих движений рынка путем изучения исторических ценовых моделей и тенденций.',
    image: bookCover9,
    isLocked: true
  },
  {
    id: 10,
    title: 'Стратегии торговли',
    description: 'Эта электронная книга была создана с целью вооружить трейдеров необходимыми навыками для простого, быстрого и интерактивного изучения различных торговых стратегий, от базовых до более продвинутых и сложных.',
    image: bookCover10,
    isLocked: true
  }
];

function Books() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
      {books.map((book) => (
        <div key={book.id} className="bg-white rounded-3xl p-6 flex shadow-sm hover:shadow-md transition-shadow">
          <div className="md:w-1/3 w-20">
            <img 
              src={book.image} 
              alt={book.title} 
              className="w-full h-auto object-cover"
            />
          </div>
          
          <div className="w-2/3 pl-6 flex flex-col">
            <h3 className="md:text-xl text-sm font-semibold mb-2">{book.title}</h3>
            <p className="text-gray-600 md:text-sm text-xs flex-grow">{book.description}</p>
            
            <button 
              className="mt-4 flex items-center text-[12px] md:text-[18px] justify-center space-x-2 bg-gray-100 text-gray-500 px-4 py-2 rounded-xl hover:bg-gray-200 transition-colors"
              disabled
            >
              <LockClosedIcon className="w-5 h-5" />
              <span>Недоступно</span>
            </button>
          </div>
        </div>
      ))}

      <BottomNav />
    </div>
   
  );
}

export default Books; 