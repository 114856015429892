import { useState, useEffect } from 'react';
import { EconomicCalendar } from "react-ts-tradingview-widgets";
import BottomNav from '../components/mobile/BottomNav';


function Calendar() {


    return (
        <div className="">
            <EconomicCalendar locale="ru" colorTheme="light" height={400} width="100%"></EconomicCalendar>
            <BottomNav />
        </div>
    );
}

export default Calendar; 