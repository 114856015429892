import { NavLink } from 'react-router-dom';
import {
  HomeIcon,
  AcademicCapIcon,
  CalendarIcon,
  ChartBarIcon,
  GiftIcon,
  PowerIcon,
  BanknotesIcon
} from '@heroicons/react/24/outline';
import logo from '../assets/images/logo.svg';

function Sidebar() {
  const navigation = [
    { name: 'Личный кабинет', icon: HomeIcon, path: '/dashboard' },
    { name: 'Активировать аккаунт', icon: PowerIcon, path: '/activate' },
    // { name: 'Пополнить', icon: BanknotesIcon, path: '/?course=silver&price=100' },
    { name: 'Обучение', icon: AcademicCapIcon, path: '/education' },
    // { name: 'Экономический календарь', icon: CalendarIcon, path: '/calendar' },
    { name: 'Трейдинг Центр', icon: ChartBarIcon, path: '/trading' },
    { name: 'Бонусы и акции', icon: GiftIcon, path: '/bonuses' },
  ];

  return (
    <div className="w-64 p-4 flex-col md:flex hidden">
      <div className="mb-8">
        <a href="/">
          <img src={logo} alt="King Academy" className="h-8" />
        </a>
      </div>
      
      <nav className="space-y-2.5">
        {navigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.path}
          >
            {({ isActive }) => (
              <div className={`flex items-center px-4 py-3.5 rounded-2xl relative transition-all duration-300 group ${
                isActive
                  ? 'bg-white shadow-[0_4px_20px_rgba(0,0,0,0.05)] before:absolute before:left-0 before:top-4 before:bottom-4 before:w-[3px] before:bg-primary before:rounded-full'
                  : ' '
              }`}>
                <div className={`p-2 rounded-xl mr-3 transition-colors duration-300 ${
                  isActive ? 'bg-primary/20' : 'bg-transparent group-hover:bg-primary/20'
                }`}>
                  <item.icon className={`h-[18px] w-[18px] transition-colors duration-300 ${
                    isActive ? 'text-[#B17A00]' : 'text-[#98A8B1] group-hover:text-[#B17A00]'
                  }`} />
                </div>
                <span className={`text-[14px] font-medium transition-colors duration-300 ${
                  isActive ? 'text-black' : 'text-[#98A8B1] group-hover:text-black'
                }`}>
                  {item.name}
                </span>
              </div>
            )}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

export default Sidebar; 