import { useState } from 'react';
import { LockClosedIcon, ClockIcon } from '@heroicons/react/24/outline';
import {
  PresentationChartLineIcon,
  CurrencyDollarIcon,
  ChartBarIcon,
  BuildingLibraryIcon,
  UserGroupIcon,
  FlagIcon,
  BoltIcon,
  ChartPieIcon,
  ArrowTrendingUpIcon,
  SignalIcon
} from '@heroicons/react/24/solid';

import BottomNav from '../components/mobile/BottomNav';

const courses = [
  {
    id: 1,
    title: 'Торговля на рынке Форекс',
    lessons: 22,
    duration: '00:20:04',
    Icon: PresentationChartLineIcon
  },
  {
    id: 2,
    title: 'Торговля криптовалютой',
    lessons: 12,
    duration: '00:16:17',
    Icon: CurrencyDollarIcon
  },
  {
    id: 3,
    title: 'Акции',
    lessons: 8,
    duration: '00:07:40',
    Icon: ChartBarIcon
  },
  {
    id: 4,
    title: 'Торговля сырьевыми товарами',
    lessons: 6,
    duration: '00:09:01',
    Icon: BuildingLibraryIcon
  },
  {
    id: 5,
    title: 'CFDs',
    lessons: 6,
    duration: '00:03:02',
    Icon: ArrowTrendingUpIcon
  },
  {
    id: 6,
    title: 'Социальная торговля',
    lessons: 5,
    duration: '00:06:04',
    Icon: UserGroupIcon
  },
  {
    id: 7,
    title: 'Торговые Стратегии',
    lessons: 10,
    duration: '00:07:17',
    Icon: FlagIcon
  },
  {
    id: 8,
    title: 'Психология торговли',
    lessons: 10,
    duration: '00:05:31',
    Icon: BoltIcon
  },
  {
    id: 9,
    title: 'Технический анализ',
    lessons: 6,
    duration: '00:03:14',
    Icon: ChartPieIcon
  },
  {
    id: 10,
    title: 'Технические индикаторы',
    lessons: 8,
    duration: '00:09:23',
    Icon: SignalIcon
  }
];

function VideoLessons() {
  const [hoveredCard, setHoveredCard] = useState(null);



  return (
    <div className="">

      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {courses.map((course) => {
          const Icon = course.Icon;
          return (
            <div
              key={course.id}
              className="relative overflow-hidden rounded-2xl bg-primary shadow-lg cursor-pointer transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
              onMouseEnter={() => setHoveredCard(course.id)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              {/* Background Pattern */}
              <div className="absolute top-0 right-0 w-32 h-32 transform translate-x-16 -translate-y-8">
                <div className="absolute inset-0 bg-black/10 rounded-full"></div>
              </div>
              
              {/* Course Content */}
              <div className="relative p-6">
                <div className="flex items-start justify-between">
                  <div className="bg-black/10 rounded-xl p-3">
                    <Icon className="h-8 w-8 text-black" />
                  </div>
                </div>
                
                <h3 className="text-xl font-bold mt-4 mb-6 text-black">{course.title}</h3>
                
                <div className="flex items-center justify-between text-sm text-black/80">
                  <div className="flex items-center space-x-1">
                    <span className="font-medium">{course.lessons}</span>
                    <span>уроков</span>
                  </div>
                  <div className="flex items-center">
                    <ClockIcon className="h-4 w-4 mr-1" />
                    <span>{course.duration}</span>
                  </div>
                </div>
              </div>

              {/* Locked Overlay */}
              {hoveredCard === course.id && (
                <div className="absolute inset-0 bg-black/75 backdrop-blur-sm flex items-center justify-center flex-col transition-all duration-300">
                  <LockClosedIcon className="h-8 w-8 text-primary mb-2" />
                  <span className="text-lg font-medium text-primary">Недоступно</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <BottomNav />
    </div>
  );
}

export default VideoLessons; 