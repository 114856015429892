import React, { useState, useEffect } from 'react';
import { FaTelegram, FaGift, FaClock, FaDollarSign, FaBookReader, FaChartLine, FaHeadset } from 'react-icons/fa';
import confetti from 'canvas-confetti';
import { useAuth } from '../contexts/AuthContext'; // Предполагаю, что у вас есть контекст авторизации
import BottomNav from '../components/mobile/BottomNav';

function Bonuses() {
  const [showOverdraftSuccess, setShowOverdraftSuccess] = useState(false);
  const { data, refreshUserData } = useAuth(); // Получаем данные пользователя из контекста

  const handleOverdraftClick = async () => {
    try {
      const response = await fetch(`https://api.king-academy.net/user/overdraft/${data?.data.user_id}`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setShowOverdraftSuccess(true);
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });

      // Обновляем данные пользователя после успешной активации
      await refreshUserData();

    } catch (error) {
      console.error('Error activating overdraft:', error);
    }
  };

  // Если overdraft уже активирован, не показываем карточку overdraft
  const showOverdraftCard = data?.data?.overdraft === false;

  return (
    <div className="space-y-8">
      {/* Header Section */}
      {/* <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Специальные предложения для вас
        </h1>
        <p className="text-lg text-gray-600">
          Получите доступ к эксклюзивным возможностям и бонусам
        </p>
      </div> */}

      <div className="grid md:grid-cols-2 gap-8">
        {/* Telegram Card */}
        <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl md:p-8 p-6 text-white shadow-xl transform hover:scale-105 transition-transform duration-300">
          <div className="flex items-center mb-6">
            <FaTelegram className="w-12 h-12" />
            <h2 className="text-xl md:text-2xl font-bold ml-4">Telegram канал</h2>
          </div>
          <p className="mb-8 text-sm md:text-lg">
            Присоединяйтесь к нашему Telegram каналу и получайте самые свежие новости, 
            аналитику и эксклюзивные торговые сигналы первыми!
          </p>
          <a 
            href="https://t.me/KingAcademyNet" 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-flex items-center md:justify-start justify-center md:w-auto w-full px-6 py-3 bg-white text-blue-600 rounded-lg font-semibold hover:bg-blue-50 transition-colors duration-300"
          >
            <FaTelegram className="mr-2" />
            Присоединиться
          </a>
        </div>

        {/* Overdraft Card - показываем только если overdraft === false */}
        {showOverdraftCard && (
          <div className="bg-gradient-to-br from-emerald-500 to-emerald-600 rounded-2xl md:p-8 p-6 text-white shadow-xl transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center mb-6">
              <FaGift className="w-12 h-12" />
              <h2 className="md:text-2xl text-xl font-bold ml-4">Overdraft</h2>
            </div>
            <div className="space-y-4 mb-8">
              <div className="flex items-center">
                <FaDollarSign className="w-6 h-6 md:mr-3 mr-1" />
                <p className="md:text-lg text-sm">Торговый депозит 500$</p>
              </div>
              <div className="flex items-center">
                <FaClock className="w-6 h-6 md:mr-3 mr-1" />
                <p className="md:text-lg text-sm">3 дня бесплатного доступа</p>
              </div>
            </div>
            {!showOverdraftSuccess ? (
              <button
                onClick={handleOverdraftClick}
                className="inline-flex md:justify-start justify-center items-center w-full md:w-auto md:text-base px-6 py-3 bg-white text-emerald-600 rounded-lg font-semibold hover:bg-emerald-50 transition-colors duration-300"
              >
                <FaGift className="mr-2" />
                Активировать
              </button>
            ) : (
              <div className="bg-white text-emerald-600 rounded-lg p-4 text-center font-semibold animate-bounce">
                🎉 Поздравляем! Вы активировали пробный период на сумму 500$
              </div>
            )}
          </div>
        )}
      </div>

      {/* Additional Benefits Section */}
      <div className="mt-12 md:bg-gray-50 md:rounded-2xl md:p-8">
        <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-6">
          Преимущества участия
        </h3>
        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
            <div className="flex items-center mb-4">
              <div className="bg-primary w-12 h-12 rounded-full flex items-center justify-center">
                <FaBookReader className="h-6 w-6 text-[#B17A00]" />
              </div>
              <h4 className="font-semibold md:text-lg text-sm ml-3">Эксклюзивный контент</h4>
            </div>
            <p className="text-gray-600 md:text-sm text-xs">Получите доступ к уникальным материалам и аналитике</p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
            <div className="flex items-center mb-4">
              <div className="bg-primary w-12 h-12 rounded-full flex items-center justify-center">
                <FaChartLine className="h-6 w-6 text-[#B17A00]" />
              </div>
              <h4 className="font-semibold md:text-lg text-sm ml-3">Торговые сигналы</h4>
            </div>
            <p className="text-gray-600 md:text-sm text-xs">Своевременные уведомления о торговых возможностях</p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
            <div className="flex items-center mb-4">
              <div className="bg-primary w-12 h-12 rounded-full flex items-center justify-center">
                <FaHeadset className="h-6 w-6 text-[#B17A00]" />
              </div>
              <h4 className="font-semibold md:text-lg text-sm ml-3">Поддержка 24/7</h4>
            </div>
            <p className="text-gray-600 md:text-sm text-xs">Круглосуточная поддержка от наших специалистов</p>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default Bonuses; 