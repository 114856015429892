import { Link } from 'react-router-dom';
import { VideoCameraIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import BottomNav from '../components/mobile/BottomNav';

function Education() {
    const sections = [
        {
            id: 'books',
            title: 'Электронные книги',
            description: 'В нашей коллекции электронных книг представлены все ключевые темы, охватывающие наиболее важные аспекты.',
            icon: DocumentTextIcon,
            path: '/education/books'
        },
        {
            id: 'video',
            title: 'Видеоуроки',
            description: 'Просматривайте обучающие видео по трейдингу, чтобы получить многолетний опыт нашей команды экспертов и торгуйте с уверенностью.',
            icon: VideoCameraIcon,
            path: '/education/video'
        }
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {sections.map((section) => (
                <div 
                    key={section.id}
                    className="bg-white rounded-[20px] p-6"
                >
                    <div className="mb-4">
                        <div className="bg-primary w-12 h-12 rounded-full flex items-center justify-center mb-4">
                            <section.icon className="h-6 w-6 text-[#B17A00]" />
                        </div>
                        <h2 className="text-xl font-bold mb-2">{section.title}</h2>
                        <p className="text-gray-600 text-sm mb-6">
                            {section.description}
                        </p>
                    </div>
                    <Link
                        to={section.path}
                        className="inline-flex text-[14px] md:text-[18px] items-center text-black font-medium bg-primary px-4 py-2 rounded-full hover:bg-primary/90 transition-colors"
                    >
                        Перейти в раздел
                    </Link>
                </div>
            ))}

            <BottomNav />
        </div>
        
    );
}

export default Education; 