import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAdminAuth } from '../contexts/AdminAuthContext';

import icon from '../assets/images/icon.svg';
import { 
  Bars3Icon, 
  XMarkIcon, 
  UserGroupIcon, 
  ArrowLeftOnRectangleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  BellIcon,
  UsersIcon
} from '@heroicons/react/24/outline';

function AdminLayout({ children }) {
  const { logout } = useAdminAuth();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };

  const navigation = [
    { name: 'Пользователи', href: '/admin/users', icon: UsersIcon },
    { name: 'Статистика', href: '/admin/statistics', icon: ChartBarIcon },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Sidebar */}
      <div className={`fixed inset-y-0 left-0 bg-gradient-to-b from-primary to-primary-dark shadow-xl transition-all duration-300 ease-in-out ${isSidebarOpen ? 'w-64' : 'w-20'}`}>
        <div className="flex flex-col h-full">
          <div className="p-4 flex items-center justify-between">
            {isSidebarOpen ? (
              <div className="flex items-center space-x-3">
<img src={icon} alt="icon" className="w-6 h-6" />
                <div>
                  <h1 className="text-sm font-bold text-black">King Academy</h1>
                  <p className="text-xs text-black/70">Admin Panel</p>
                </div>
              </div>
            ) : (
                <div className="flex items-center justify-center w-full">
                    <img src={icon} alt="icon" className="w-6 h-6" />
                </div>
            )}
            {/* <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 hover:bg-white/10 rounded-lg text-white/70 hover:text-white transition-colors"
            >
              {isSidebarOpen ? (
                <ChevronLeftIcon className="w-5 h-5" />
              ) : (
                <ChevronRightIcon className="w-5 h-5" />
              )}
            </button> */}
          </div>
          
          <nav className="flex-1 px-3 py-6 space-y-1">
            <Link
              to="/admin/users"
              className={`flex items-center justify-center px-3 py-2.5 text-black rounded-lg transition-all ${
                window.location.pathname === '/admin/users' 
                  ? 'bg-white/10 text-black' 
                  : 'hover:bg-white/5'
              }`}
            >
              <UserGroupIcon className="w-5 h-5" />
              {isSidebarOpen && <span className="ml-3 text-sm font-medium">Пользователи</span>}
            </Link>
            <Link
              to="/admin/statistics"
              className="flex items-center justify-center px-3 py-2.5 text-black rounded-lg transition-all"
            >
              <ChartBarIcon className="w-5 h-5" />
              {isSidebarOpen && <span className="ml-3 text-sm font-medium">Статистика</span>}
            </Link>
          </nav>

          <div className="p-3 border-t border-white/10">
            <button
              onClick={handleLogout}
              className="flex items-center justify-center w-full px-3 py-2.5 text-black bg-primary rounded-lg transition-all hover:bg-white/5"
            >
              <ArrowLeftOnRectangleIcon className="w-5 h-5" />
              {isSidebarOpen && <span className="ml-3 text-[18px] font-medium">Выйти</span>}
            </button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className={`transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-20'}`}>
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="px-6 py-4 flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="p-2 hover:bg-gray-100 rounded-lg mr-4 text-gray-500 hover:text-gray-700 transition-colors"
              >
                <Bars3Icon className="w-5 h-5" />
              </button>
              <h2 className="text-xl font-semibold text-gray-900">Панель управления</h2>
            </div>
            <div className="flex items-center space-x-4">
              <button className="p-2 hover:bg-gray-100 rounded-lg text-gray-500 hover:text-gray-700 transition-colors">
                <BellIcon className="w-5 h-5" />
              </button>
              <div className="h-9 w-9 bg-primary rounded-full flex items-center justify-center text-white font-medium text-sm">
                A
              </div>
            </div>
          </div>
        </header>

        {/* Page content */}
        <main className="p-6">
          {children}
        </main>
      </div>
    </div>
  );
}

export default AdminLayout; 