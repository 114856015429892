import React, { useState, useEffect } from 'react';
import { useAdminAuth } from '../../contexts/AdminAuthContext';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Регистрируем компоненты Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Statistics() {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [period, setPeriod] = useState('week'); // day, week, month, year
  const { getAuthHeader } = useAdminAuth();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch('https://api.king-academy.net/admin/user', {
        headers: getAuthHeader()
      });

      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await response.json();
      setUserData(data.data);
    } catch (err) {
      setError('Ошибка при загрузке данных');
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('ru-RU').format(num);
  };

  const groupDataByPeriod = (data, selectedPeriod) => {
    const now = new Date();
    let startDate;

    switch (selectedPeriod) {
      case 'day':
        startDate = new Date(now.setDate(now.getDate() - 1));
        break;
      case 'week':
        startDate = new Date(now.setDate(now.getDate() - 7));
        break;
      case 'month':
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case 'year':
        startDate = new Date(now.setFullYear(now.getFullYear() - 1));
        break;
      default:
        startDate = new Date(now.setDate(now.getDate() - 7));
    }

    // Фильтруем данные по выбранному периоду
    const filteredData = data.filter(user => new Date(user.created_at) >= startDate);

    // Группируем по датам
    const groupedData = filteredData.reduce((acc, user) => {
      const date = new Date(user.created_at).toLocaleDateString();
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    // Сортируем даты
    const sortedDates = Object.keys(groupedData).sort((a, b) => new Date(a) - new Date(b));

    return {
      labels: sortedDates,
      data: sortedDates.map(date => groupedData[date])
    };
  };

  const prepareChartData = () => {
    if (!userData) return null;

    const groupedData = groupDataByPeriod(userData, period);
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(59, 130, 246, 0.3)');
    gradient.addColorStop(1, 'rgba(59, 130, 246, 0)');

    return {
      labels: groupedData.labels,
      datasets: [{
        label: 'Регистрации',
        data: groupedData.data,
        borderColor: '#3B82F6',
        borderWidth: 2,
        backgroundColor: gradient,
        fill: true,
        tension: 0.4,
        pointBackgroundColor: '#3B82F6',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: '#3B82F6',
        pointHoverBorderColor: '#ffffff',
        pointHoverBorderWidth: 2,
      }]
    };
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Количество регистраций',
        font: {
          size: 16,
          weight: 'bold'
        },
        padding: 20,
        color: '#111827'
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        titleColor: '#111827',
        titleFont: {
          size: 14,
          weight: 'bold'
        },
        bodyColor: '#4B5563',
        bodyFont: {
          size: 13
        },
        padding: 12,
        borderColor: '#E5E7EB',
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          title: (items) => items[0].label,
          label: (item) => `Регистраций: ${item.raw}`
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
          font: {
            size: 12
          },
          color: '#6B7280'
        },
        grid: {
          color: '#F3F4F6',
          drawBorder: false
        },
        border: {
          display: false
        }
      },
      x: {
        ticks: {
          font: {
            size: 12
          },
          color: '#6B7280'
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    elements: {
      line: {
        borderJoinStyle: 'round'
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  const chartData = prepareChartData();

  if (!chartData) return null;

  return (
    <div className="p-6">
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Статистика регистраций</h2>
            <p className="text-gray-600 mt-1">Анализ роста пользователей</p>
          </div>
          <div className="flex space-x-2">
            {[
              { id: 'day', label: 'День' },
              { id: 'week', label: 'Неделя' },
              { id: 'month', label: 'Месяц' },
              { id: 'year', label: 'Год' }
            ].map((option) => (
              <button
                key={option.id}
                onClick={() => setPeriod(option.id)}
                className={`px-4 py-2 rounded-xl font-medium transition-colors ${
                  period === option.id
                    ? 'bg-primary text-black'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 text-red-700 rounded-xl">
          {error}
        </div>
      )}

      {/* График регистраций */}
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="h-[400px]">
          <Line options={chartOptions} data={chartData} />
        </div>
      </div>

      {/* Общая статистика */}
      <div className="mt-6 bg-white rounded-xl shadow-sm p-6">
        <div className="text-center">
          <p className="text-gray-600">Всего пользователей</p>
          <p className="text-3xl font-bold text-gray-900 mt-1">{formatNumber(userData.length)}</p>
        </div>
      </div>
    </div>
  );
}

export default Statistics; 