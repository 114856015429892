import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Функция для получения данных пользователя
  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }

      const response = await fetch("https://api.king-academy.net/user", {
        method: "GET",
        headers: {
          "token": JSON.parse(token)
        }
      });

      const userData = await response.json();
      
      if (response.ok && userData) {
        setData(userData);
        setIsAuthenticated(true);
        setError(null);
      } else {
        setIsAuthenticated(false);
        localStorage.removeItem('token');
        throw new Error('Failed to fetch user data');
      }
    } catch (err) {
      setError(err.message);
      setIsAuthenticated(false);
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  };

  // Функция для обновления данных пользователя
  const refreshUserData = async () => {
    await fetchUserData();
  };

  // Функция для выхода
  const logout = () => {
    localStorage.removeItem('token');
    setData(null);
    setIsAuthenticated(false);
  };

  // Функция для входа
  const login = async (token) => {
    localStorage.setItem('token', JSON.stringify(token));
    await fetchUserData();
  };

  // Загружаем данные пользователя при первом рендере
  useEffect(() => {
    fetchUserData();
  }, []);

  const value = {
    data,
    loading,
    error,
    isAuthenticated,
    refreshUserData,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : (
        <div className="min-h-screen flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
      )}
    </AuthContext.Provider>
  );
} 