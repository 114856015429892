import React from 'react';
import { Link } from 'react-router-dom';
import { FaChartLine, FaCoins, FaBoxes, FaChartBar, FaWallet, FaChartPie } from 'react-icons/fa';
import BottomNav from '../components/mobile/BottomNav';

function Trading() {
  const tradingCards = [
    {
      title: 'Акции',
      description: 'Акции известных компаний – один из самых популярных и прибыльных вариантов инвестирования и трейдинга! Приобретайте акции гигантов рынка вместе с King Academy!',
      icon: <FaChartLine className="w-6 h-6 text-[#B17A00]" />,
      
    },
    {
      title: 'Валюты',
      description: 'Следите за актуальными курсами, пользуйтесь встроенным онлайн-конвертером и узнавайте больше о главных мировых валютах на рынке!',
      icon: <FaCoins className="w-6 h-6 text-[#B17A00]" />,
      
    },
    {
      title: 'Индексы',
      description: 'Получите возможность торговли несколькими активами в одно и то же время, чтобы значительно увеличить шансы на более весомый профит!',
      icon: <FaChartBar className="w-6 h-6 text-[#B17A00]" />,
      
    },
    {
      title: 'Криптовалюты',
      description: 'Торгуйте одним из самых быстроразвивающихся финансовых инструментов и получайте прибыль наравне с профессиональными трейдерами!',
      icon: <FaWallet className="w-6 h-6 text-[#B17A00]" />,
    
    },
    {
      title: 'Товары',
      description: 'Приобретайте и продавайте различные товары: от энергоресурсов и до металлов, чтобы диверсифицировать портфель и избежать сильных колебаний на рынке!',
      icon: <FaBoxes className="w-6 h-6 text-[#B17A00]" />,
      
    },
    {
      title: 'ETF',
      description: 'Получайте большую выгоду от торговли ETF фондами! В этот инструмент входят акции нескольких компаний, которые включают в себя капитал огромного числа инвесторов!',
      icon: <FaChartPie className="w-6 h-6 text-[#B17A00]" />,
      
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {tradingCards.map((card, index) => (
        <div
          key={index}
          className="bg-white rounded-[20px] p-6"
        >
          <div className="flex flex-col h-full">
            <div className="flex items-center mb-4">
              <div className="bg-primary rounded-full w-12 h-12 flex items-center justify-center">
                {card.icon}
              </div>
              <h3 className="text-xl font-semibold ml-3">{card.title}</h3>
            </div>
            <p className="text-gray-600 text-sm mb-6">{card.description}</p>
          </div>
        </div>
      ))}
      <BottomNav />
    </div>
  );
}

export default Trading; 