import { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import PhoneInput from 'react-phone-input-2';
import { parsePhoneNumber } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css';
import logo from '../assets/images/logo.svg';

// Добавим стили для PhoneInput
const phoneInputCustomStyles = `
  .react-tel-input .form-control {
    width: 100% !important;
    height: 48px !important;
    border-radius: 16px !important;
    border: 1px solid #E5E7EB !important;
    font-size: 16px !important;
    padding-left: 48px !important;
    background: white !important;
  }

  .react-tel-input .form-control:focus {
    border-color: #B17A00 !important;
    box-shadow: 0 0 0 1px #B17A00 !important;
  }

  .react-tel-input .flag-dropdown {
    background: transparent !important;
    border: none !important;
    border-radius: 16px 0 0 16px !important;
  }

  .react-tel-input .selected-flag {
    background: transparent !important;
    border-radius: 16px 0 0 16px !important;
    width: 40px !important;
  }

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background: transparent !important;
  }

  .react-tel-input .country-list {
    border-radius: 12px !important;
    border: 1px solid #E5E7EB !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    margin-top: 8px !important;
  }

  .react-tel-input .country-list .country:hover {
    background: #F3F4F6 !important;
  }

  .react-tel-input .country-list .country.highlight {
    background: #F3F4F6 !important;
  }
`;

function Register() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [userCountry, setUserCountry] = useState('ru');
  const navigate = useNavigate();
  const form = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handlePhoneChange = (value, data) => {
    try {
      // Добавляем '+' к номеру, так как библиотека ожидает его
      const phoneNumber = parsePhoneNumber('+' + value);
      
      if (phoneNumber) {
        // Получаем отформатированный номер в международном формате
        const formattedNumber = phoneNumber.formatInternational();
        setFormData(prev => ({
          ...prev,
          phone: formattedNumber
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          phone: '+' + value
        }));
      }
    } catch (error) {
      // Если что-то пошло не так, сохраняем как есть
      setFormData(prev => ({
        ...prev,
        phone: '+' + value
      }));
    }

    if (errors.phone) {
      setErrors(prev => ({
        ...prev,
        phone: ''
      }));
    }
  };

  const validatePassword = (password) => {
    const hasNumber = /[0-9]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasMinLength = password.length >= 8;

    const errors = [];
    if (!hasNumber) errors.push('цифру');
    if (!hasLowerCase) errors.push('строчную букву');
    if (!hasUpperCase) errors.push('заглавную букву');
    if (!hasMinLength) errors.push('минимум 8 символов');

    return errors;
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'Введите имя';
    } else if (/\d/.test(formData.firstName)) {
      newErrors.firstName = 'Имя не должно содержать цифры';
    }
    
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Введите фамилию';
    } else if (/\d/.test(formData.lastName)) {
      newErrors.lastName = 'Фамилия не должна содержать цифры';
    }

    if (!formData.email) {
      newErrors.email = 'Введите email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Неверный формат email';
    }

    if (!formData.phone) {
      newErrors.phone = 'Введите телефон';
    }

    if (!formData.password) {
      newErrors.password = 'Введите пароль';
    } else {
      const passwordErrors = validatePassword(formData.password);
      if (passwordErrors.length > 0) {
        newErrors.password = `Пароль должен содержать: ${passwordErrors.join(', ')}`;
      }
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Подтвердите пароль';
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Пароли не совпадают';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isSubmitting) return;
    
    setErrors({});
    setSuccessMessage('');

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch("https://api.king-academy.net/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: formData.firstName,
          lastname: formData.lastName,
          password: formData.password,
          email: formData.email,
          contact: formData.phone
        })
      });

      const data = await response.json();

      if (data.token) {
        setSuccessMessage('Registration successful');
        localStorage.setItem("token", JSON.stringify(data.token));
        window.location = '/dashboard';
      } else {
        setErrors(prev => ({
          ...prev,
          submit: data.message || 'Ошибка регистрации'
        }));
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrors(prev => ({
        ...prev,
        submit: 'Произошла ошибка при регистрации'
      }));
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex bg-gray-50">
      <style>{phoneInputCustomStyles}</style>
      {/* Left side - Decoration */}
      <div className="hidden lg:block lg:w-1/2 relative">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-custom"
        />
        <div className="absolute inset-x-0 bottom-24 p-8">
          <div className="text-center">
            <h3 className="text-3xl font-bold text-black mb-4">
              Присоединяйтесь <br/> к King Academy
            </h3>
            <p className="text-black/90 max-w-md mx-auto">
              Начните свое путешествие <br/> в мире трейдинга вместе с нами
            </p>
          </div>
        </div>
      </div>

      {/* Right side - Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="w-full max-w-md">
          <div className="mb-8 text-center">
            <a href="https://king-academy.online">
              <img src={logo} alt="King Academy" className="h-12 mx-auto mb-6" />
            </a>
            <h2 className="text-2xl font-bold text-gray-900">Создайте аккаунт</h2>
            {/* <p className="text-gray-600 mt-2">Заполните форму для регистрации</p> */}
          </div>

          <form ref={form} onSubmit={handleSubmit} className="space-y-4">
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Имя"
                  className={`w-full  px-4 py-3 rounded-2xl bg-white border ${
                    errors.firstName ? 'border-red-500' : 'border-gray-200'
                  } text-gray-900 placeholder-gray-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary transition-colors`}
                />
                {errors.firstName && (
                  <p className="mt-1 text-sm text-red-500">{errors.firstName}</p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Фамилия"
                  className={`w-full px-4 py-3 rounded-2xl bg-white border ${
                    errors.lastName ? 'border-red-500' : 'border-gray-200'
                  } text-gray-900 placeholder-gray-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary transition-colors`}
                />
                {errors.lastName && (
                  <p className="mt-1 text-sm text-red-500">{errors.lastName}</p>
                )}
              </div>
            </div>

            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Электронная почта"
                className={`w-full px-4 py-3 rounded-2xl bg-white border ${
                  errors.email ? 'border-red-500' : 'border-gray-200'
                } text-gray-900 placeholder-gray-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary transition-colors`}
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">{errors.email}</p>
              )}
            </div>

            <div>
              <PhoneInput
                country={userCountry}
                value={formData.phone}
                onChange={handlePhoneChange}
                containerClass="w-full"
                inputClass={`w-full ${errors.phone ? 'border-red-500' : 'border-gray-200'}`}
                buttonClass="phone-input-button"
                dropdownClass="phone-input-dropdown"
                enableSearch={true}
                searchPlaceholder="Поиск страны..."
                searchNotFound="Страна не найдена"
                preferredCountries={['ru', 'by', 'ua', 'kz']}
              />
              {errors.phone && (
                <p className="mt-1 text-sm text-red-500">{errors.phone}</p>
              )}
            </div>

            <div className="relative">
              <input
                type={showPassword.password ? 'text' : 'password'}
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Пароль"
                className={`w-full px-4 py-3 rounded-2xl bg-white border ${
                  errors.password ? 'border-red-500' : 'border-gray-200'
                } text-gray-900 placeholder-gray-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary transition-colors`}
              />
              <button
                type="button"
                onClick={() => setShowPassword(prev => ({ ...prev, password: !prev.password }))}
                className="absolute right-4 top-[16px] text-gray-400 hover:text-gray-600 transition-colors"
              >
                {showPassword.password ? (
                  <EyeSlashIcon className="w-5 h-5" />
                ) : (
                  <EyeIcon className="w-5 h-5" />
                )}
              </button>
              {errors.password && (
                <p className="mt-1 text-sm text-red-500">{errors.password}</p>
              )}
            </div>

            <div className="relative">
              <input
                type={showPassword.confirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Подтвердите пароль"
                className={`w-full px-4 py-3 rounded-2xl bg-white border ${
                  errors.confirmPassword ? 'border-red-500' : 'border-gray-200'
                } text-gray-900 placeholder-gray-400 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary transition-colors`}
              />
              <button
                type="button"
                onClick={() => setShowPassword(prev => ({ ...prev, confirmPassword: !prev.confirmPassword }))}
                className="absolute right-4 top-[16px] text-gray-400 hover:text-gray-600 transition-colors"
              >
                {showPassword.confirmPassword ? (
                  <EyeSlashIcon className="w-5 h-5" />
                ) : (
                  <EyeIcon className="w-5 h-5" />
                )}
              </button>
              {errors.confirmPassword && (
                <p className="mt-1 text-sm text-red-500">{errors.confirmPassword}</p>
              )}
            </div>

            {errors.submit && (
              <div className="text-red-500 text-sm text-center">
                {errors.submit}
              </div>
            )}

            {successMessage && (
              <div className="text-green-500 text-sm text-center font-medium">
                {successMessage}
              </div>
            )}

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full py-3 px-4 bg-primary text-black font-medium rounded-2xl 
                          transition-all duration-200
                          ${isSubmitting 
                            ? 'opacity-70 cursor-not-allowed' 
                            : 'hover:bg-primary/90'}`}
            >
              {isSubmitting ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-2 border-black border-t-transparent rounded-full animate-spin mr-2"></div>
                  Регистрация...
                </div>
              ) : (
                'Зарегистрироваться'
              )}
            </button>

            <div className="text-center mt-6">
              <Link
                to="/login"
                  className="text-gray-600 hover:text-primary transition-colors underline"
              >
                Уже есть аккаунт? Войти
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register; 