import React, { useState, useEffect } from 'react';
import { useAdminAuth } from '../../contexts/AdminAuthContext';
import { TrashIcon, PencilIcon, UserGroupIcon, CurrencyDollarIcon, AcademicCapIcon } from '@heroicons/react/24/outline';


const COURSE_OPTIONS = [
  { value: 'NONE', label: 'Нет курса' },
  { value: 'SILVER', label: 'Silver' },
  { value: 'GOLD', label: 'Gold' },
  { value: 'PLATINUM', label: 'Platinum' },
  { value: 'CUSTOM', label: 'Свой вариант' }
];

const ITEMS_PER_PAGE = 100;

function formatDate(dateString) {
  return new Date(dateString).toLocaleString('ru-RU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
}

function getInitials(username, lastname) {
  if (!username && !lastname) return '?';
  return `${(username?.[0] || '').toUpperCase()}${(lastname?.[0] || '').toUpperCase()}`;
}

function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [editingBalance, setEditingBalance] = useState(null);
  const [editingCourse, setEditingCourse] = useState(null);
  const [newBalance, setNewBalance] = useState('');
  const [customCourse, setCustomCourse] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const { getAuthHeader } = useAdminAuth();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (user.user_id?.toString() || '').includes(searchLower) ||
        (user.email?.toLowerCase() || '').includes(searchLower) ||
        (user.contact?.toLowerCase() || '').includes(searchLower)
      );
    });
    setFilteredUsers(filtered);
    setCurrentPage(1);
  }, [searchQuery, users]);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      setError('');
      
      const response = await fetch('https://api.king-academy.net/admin/user', {
        headers: getAuthHeader()
      });

      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }

      const result = await response.json();
      
      if (result.status === 200 && Array.isArray(result.data)) {
        const sortedUsers = result.data.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        });
        setUsers(sortedUsers);
      } else {
        console.error('Invalid response format:', result);
        setError('Неверный формат данных');
      }
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Ошибка при загрузке пользователей');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Вы уверены, что хотите удалить этого пользователя?')) {
      return;
    }

    try {
      const response = await fetch(`https://api.king-academy.net/admin/user/delete/${userId}`, {
        method: 'DELETE',
        headers: getAuthHeader()
      });

      if (!response.ok) {
        throw new Error('Failed to delete user');
      }

      fetchUsers();
    } catch (err) {
      setError('Ошибка при удалении пользователя');
      console.error('Error deleting user:', err);
    }
  };

  const handleBalanceUpdate = async (userId) => {
    try {
      const response = await fetch(`https://api.king-academy.net/user/${userId}/balance`, {
        method: 'PUT',
        headers: {
          ...getAuthHeader(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ balance: Number(newBalance) })
      });

      if (!response.ok) {
        throw new Error('Failed to update balance');
      }

      setEditingBalance(null);
      setNewBalance('');
      fetchUsers();
    } catch (err) {
      setError('Ошибка при обновлении баланса');
      console.error('Error updating balance:', err);
    }
  };

  const handleCourseUpdate = async (userId, newCourse) => {
    try {
      const courseValue = newCourse === 'CUSTOM' ? customCourse : newCourse;
      
      const response = await fetch(`https://api.king-academy.net/user/${userId}/course`, {
        method: 'PUT',
        headers: {
          ...getAuthHeader(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ course: courseValue })
      });

      if (!response.ok) {
        throw new Error('Failed to update course');
      }

      setEditingCourse(null);
      setCustomCourse('');
      setShowCustomInput(false);
      fetchUsers();
    } catch (err) {
      setError('Ошибка при обновлении курса');
      console.error('Error updating course:', err);
    }
  };

  const totalPages = Math.ceil(filteredUsers.length / ITEMS_PER_PAGE);
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Статистика
  const stats = {
    totalUsers: users.length,
    totalBalance: users.reduce((sum, user) => sum + Number(user.balance), 0),
    courses: {
      NONE: users.filter(user => user.course === 'NONE').length,
      SILVER: users.filter(user => user.course === 'SILVER').length,
      GOLD: users.filter(user => user.course === 'GOLD').length,
      PLATINUM: users.filter(user => user.course === 'PLATINUM').length
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      {/* <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900">Управление пользователями</h2>
        <p className="text-gray-600 mt-1">Просмотр и управление пользователями платформы</p>
      </div> */}

      {/* Статистика */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-white rounded-xl shadow-sm p-4">
          <div className="flex items-center">
            <div className="p-2 bg-blue-50 rounded-lg">
              <UserGroupIcon className="h-6 w-6 text-blue-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-600">Всего пользователей</p>
              <p className="text-lg font-semibold text-gray-900">{stats.totalUsers}</p>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-sm p-4">
          <div className="flex items-center">
            <div className="p-2 bg-green-50 rounded-lg">
              <CurrencyDollarIcon className="h-6 w-6 text-green-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-600">Общий баланс</p>
              <p className="text-lg font-semibold text-gray-900">{stats.totalBalance}</p>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-sm p-4">
          <div className="flex items-center">
            <div className="p-2 bg-purple-50 rounded-lg">
              <AcademicCapIcon className="h-6 w-6 text-purple-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-600">Активные курсы</p>
              <p className="text-lg font-semibold text-gray-900">
                {stats.courses.SILVER + stats.courses.GOLD + stats.courses.PLATINUM}
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-sm p-4">
          <div className="flex items-center">
            <div className="p-2 bg-yellow-50 rounded-lg">
              <AcademicCapIcon className="h-6 w-6 text-yellow-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-600">Без курса</p>
              <p className="text-lg font-semibold text-gray-900">{stats.courses.NONE}</p>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 text-red-700 rounded-xl">
          {error}
        </div>
      )}

      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder="Поиск по ID, email или телефону..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-2 rounded-xl border border-gray-200 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
          />
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        <div className="overflow-x-auto max-w-full">
          <div className="min-w-[1200px]">
            <table className="w-full table-fixed divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-4 w-[250px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Имя и фамилия
                  </th>
                  <th className="px-6 py-4 w-[100px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="px-6 py-4 w-[200px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-4 w-[150px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Телефон
                  </th>
                  <th className="px-6 py-4 w-[150px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Пароль
                  </th>
                  <th className="px-6 py-4 w-[120px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Баланс
                  </th>
                  <th className="px-6 py-4 w-[120px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Курс
                  </th>
                  <th className="px-6 py-4 w-[150px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Дата рег.
                  </th>
                  <th className="px-6 py-4 w-[80px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Дейст.
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {paginatedUsers.map((user) => (
                  <tr key={user.user_id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 w-[150px] whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="h-8 w-8 flex-shrink-0">
                          {user.avatar ? (
                            <img
                              className="h-8 w-8 rounded-full"
                              src={user.avatar}
                              alt=""
                            />
                          ) : (
                            <div className="h-8 w-8 rounded-full bg-primary flex items-center justify-center text-white text-xs font-medium">
                              {getInitials(user.username, user.lastname)}
                            </div>
                          )}
                        </div>
                        <div className="ml-3">
                          <div className="text-xs font-medium text-gray-900">
                            {user.username} {user.lastname}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 w-[100px] whitespace-nowrap">
                      <div className="text-xs text-gray-900">{user.user_id}</div>
                    </td>
                    <td className="px-6 py-4 w-[200px] whitespace-nowrap">
                      <div className="text-xs text-gray-900">{user.email}</div>
                    </td>
                    <td className="px-6 py-4 w-[150px] whitespace-nowrap">
                      <div className="text-xs text-gray-900">{user.contact}</div>
                    </td>
                    <td className="px-6 py-4 w-[150px] whitespace-nowrap">
                      <div className="text-xs text-gray-900">{user.password}</div>
                    </td>
                    <td className="px-6 py-4 w-[120px] whitespace-nowrap">
                      <div className="flex flex-col space-y-1">
                        <div className="flex items-center space-x-2">
                          <div className="text-xs text-gray-900">{user.balance}</div>
                          <button
                            onClick={() => {
                              setEditingBalance(user.user_id);
                              setNewBalance(user.balance);
                            }}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            <PencilIcon className="h-3 w-3" />
                          </button>
                        </div>
                        {user.overdraft && (
                          <div className="inline-flex items-center px-2 py-0.5 rounded text-[10px] font-medium bg-yellow-100 text-yellow-800">
                            Овердрафт
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 w-[120px] whitespace-nowrap">
                      <div className="flex items-center space-x-2">
                        <div className="text-xs text-gray-900">{user.course || 'Нет курса'}</div>
                        <button
                          onClick={() => setEditingCourse(user.user_id)}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          <PencilIcon className="h-3 w-3" />
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-4 w-[150px] whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {user.created_at ? formatDate(user.created_at) : 'Нет даты'}
                      </div>
                    </td>
                    <td className="px-6 py-4 w-[80px] whitespace-nowrap text-xs font-medium">
                      <button
                        onClick={() => handleDeleteUser(user.user_id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <div className="text-sm text-gray-700">
          Показано {((currentPage - 1) * ITEMS_PER_PAGE) + 1} - {Math.min(currentPage * ITEMS_PER_PAGE, filteredUsers.length)} из {filteredUsers.length} пользователей
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
          >
            Назад
          </button>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
          >
            Вперед
          </button>
        </div>
      </div>

      {/* Модальное окно для баланса */}
      {editingBalance && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl shadow-lg max-w-md w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Изменить баланс</h3>
            <div className="space-y-4">
              <div>
                <label htmlFor="balance" className="block text-sm font-medium text-gray-700 mb-1">
                  Новый баланс
                </label>
                <input
                  type="number"
                  id="balance"
                  value={newBalance}
                  onChange={(e) => setNewBalance(e.target.value)}
                  className="w-full px-4 py-2 rounded-xl border border-gray-200 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                  required
                />
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => {
                    setEditingBalance(null);
                    setNewBalance('');
                  }}
                  className="px-4 py-2 text-gray-700 hover:text-gray-900"
                >
                  Отмена
                </button>
                <button
                  onClick={() => handleBalanceUpdate(editingBalance)}
                  className="px-4 py-2 bg-primary text-black font-medium rounded-xl hover:bg-primary/90 transition-colors"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Модальное окно для курса */}
      {editingCourse && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl shadow-lg max-w-md w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Выбрать курс</h3>
            <div className="space-y-4">
              {!showCustomInput ? (
                <div className="grid grid-cols-2 gap-4">
                  {COURSE_OPTIONS.map(option => (
                    <button
                      key={option.value}
                      onClick={() => {
                        if (option.value === 'CUSTOM') {
                          setShowCustomInput(true);
                        } else {
                          handleCourseUpdate(editingCourse, option.value);
                        }
                      }}
                      className="px-4 py-2 border border-gray-200 rounded-xl hover:bg-gray-50 text-left"
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              ) : (
                <div className="space-y-4">
                  <div>
                    <label htmlFor="customCourse" className="block text-sm font-medium text-gray-700 mb-1">
                      Введите название курса
                    </label>
                    <input
                      type="text"
                      id="customCourse"
                      value={customCourse}
                      onChange={(e) => setCustomCourse(e.target.value)}
                      className="w-full px-4 py-2 rounded-xl border border-gray-200 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                      placeholder="Введите название курса"
                      required
                    />
                  </div>
                  <div className="flex justify-end space-x-4">
                    <button
                      onClick={() => {
                        setShowCustomInput(false);
                        setCustomCourse('');
                      }}
                      className="px-4 py-2 text-gray-700 hover:text-gray-900"
                    >
                      Назад
                    </button>
                    <button
                      onClick={() => handleCourseUpdate(editingCourse, 'CUSTOM')}
                      disabled={!customCourse.trim()}
                      className="px-4 py-2 bg-primary text-black font-medium rounded-xl hover:bg-primary/90 transition-colors disabled:opacity-50"
                    >
                      Сохранить
                    </button>
                  </div>
                </div>
              )}
              {!showCustomInput && (
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      setEditingCourse(null);
                      setCustomCourse('');
                      setShowCustomInput(false);
                    }}
                    className="px-4 py-2 text-gray-700 hover:text-gray-900"
                  >
                    Отмена
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Users; 