import { Link } from 'react-router-dom';
import { ChevronRightIcon, UserCircleIcon, Cog6ToothIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useState, useRef, useEffect } from 'react';

function UserCard() {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            if (!token) return;

            try {
                const response = await fetch("https://api.king-academy.net/user", {
                    method: "GET",
                    headers: {
                        "token": JSON.parse(token)
                    }
                });

                const data = await response.json();
                if (data.status === 200) {
                    setUserData(data.data);
                    console.log('User data:', data.data);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    


    const getDisplayStatus = () => {
        if (!userData) return 'Загрузка...';
        
        if (userData.course && userData.course !== "NONE") {
            return userData.course;
        }
        
        if (userData.overdraft) {
            return "OVERDRAFT";
        }
        
        return "Нет курса";
    };

    const getInitials = (firstName, lastName) => {
        const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
        const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
        return `${firstInitial}${lastInitial}`;
    };

    return (
        <div className="mt-4">
            <div className="md:hidden">
                <div className="relative w-full aspect-[16/9] max-w-[400px] mx-auto">
                    {/* Основная карточка */}
                    <div className="absolute inset-0 bg-gradient-to-br from-[#1C1C1E] to-[#2C2C2E] rounded-[25px] overflow-hidden">
                        {/* Футуристический фон */}
                        <div className="absolute inset-0 opacity-20">
                            <div className="absolute h-[1px] w-full top-[30%] left-0 bg-gradient-to-r from-transparent via-white/50 to-transparent"></div>
                            <div className="absolute h-[1px] w-full top-[60%] left-0 bg-gradient-to-r from-transparent via-white/30 to-transparent"></div>
                            <div className="absolute h-full w-[1px] top-0 left-[30%] bg-gradient-to-b from-transparent via-white/50 to-transparent"></div>
                            <div className="absolute h-full w-[1px] top-0 left-[70%] bg-gradient-to-b from-transparent via-white/30 to-transparent"></div>
                            
                            {/* Анимированные частицы */}
                            <div className="absolute w-1 h-1 bg-white/50 rounded-full top-[20%] left-[20%] animate-pulse"></div>
                            <div className="absolute w-1 h-1 bg-white/30 rounded-full top-[40%] left-[60%] animate-pulse delay-75"></div>
                            <div className="absolute w-1 h-1 bg-white/40 rounded-full top-[70%] left-[40%] animate-pulse delay-150"></div>
                            
                            {/* Неоновые линии */}
                            <div className="absolute w-[200px] h-[1px] bg-gradient-to-r from-transparent via-cyan-500/20 to-transparent 
                                          transform -rotate-45 top-1/3 -left-20"></div>
                            <div className="absolute w-[200px] h-[1px] bg-gradient-to-r from-transparent via-purple-500/20 to-transparent 
                                          transform rotate-45 bottom-1/3 -right-20"></div>
                        </div>

                        {/* Основной контент */}
                        <div className="relative h-full p-5 flex flex-col justify-between">
                            {/* Верхняя часть */}
                            <div className="flex items-center justify-between">
                                <div className="space-y-1">
                                    <div className="text-white/50 text-xs tracking-wider">BALANCE</div>
                                    <div className="text-white font-light text-[16px] tracking-wider">
                                        {userData?.balance || 0}.00
                                        <span className="text-white/50 text-sm">USD</span>
                                    </div>
                                </div>
                                <div className="px-3 py-1 rounded-full bg-white/5 backdrop-blur-sm 
                                              border border-white/10 shadow-inner">
                                    <span className="text-white/90 text-sm font-light tracking-wider">
                                        {getDisplayStatus()}
                                    </span>
                                </div>
                            </div>

                            {/* Нижняя часть */}
                            <div className="flex items-center space-x-4">
                                <div className="relative">
                                    <div className="w-12 h-12 rounded-full bg-white/5 backdrop-blur-sm 
                                                  border border-white/10 flex items-center justify-center
                                                  shadow-inner">
                                        <span className="text-white/90 font-light text-lg">
                                            {getInitials(userData?.username, userData?.lastname)}
                                        </span>
                                    </div>
                                    {/* Декоративный элемент */}
                                    <div className="absolute -inset-[2px] rounded-full border border-white/20 
                                                  animate-pulse"></div>
                                </div>
                                <div className="space-y-1">
                                    <div className="text-white/50 text-xs tracking-wider">Аккаунт</div>
                                    <div className="text-white/90 font-light tracking-wider md:text-[16px] text-[12px]">
                                        {userData?.username || 'Guest'} {userData?.lastname || ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserCard; 