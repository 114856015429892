import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Education from './pages/Education';
import Activate from './pages/Activate';
import Layout from './components/Layout';
import AdminLayout from './components/AdminLayout';
import Calendar from './pages/Calendar';
import ProtectedRoute from './components/ProtectedRoute';
import Trading from './pages/Trading';
import Bonuses from './pages/Bonuses';
import Books from './pages/Books';
import Settings from './pages/Settings';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { AdminAuthProvider, useAdminAuth } from './contexts/AdminAuthContext';
import AdminLogin from './pages/admin/AdminLogin';
import Users from './pages/admin/Users';
import Checkout from './pages/Checkout';
import Payment from './components/Payment';
import Statistics from './pages/admin/Statistics';
import VideoLessons from './pages/VideoLessons';
import ScrollToTop from './components/ScrollToTop';

// Компонент для защиты административных маршрутов
const AdminRoute = ({ children }) => {
  const { isAuthenticated: isAdminAuthenticated, isLoading } = useAdminAuth();
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!isAdminAuthenticated) {
    return <Navigate to="/admin/login" />;
  }

  return children;
};

function AppRoutes() {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/dashboard" />} />
      <Route path="/register" element={!isAuthenticated ? <Register /> : <Navigate to="/dashboard" />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      {/* Additional routes */}
      <Route path="/checkout" element={<Checkout />} />
      {/* Protected routes */}
      <Route path="/" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Layout /></ProtectedRoute>}>
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="education" element={<Education />} />
        <Route path="education/video" element={<VideoLessons />} />
        <Route path="education/books" element={<Books />} />
        <Route path="activate" element={<Activate />} />
        <Route path="calendar" element={<Calendar />} />
        <Route path="trading" element={<Trading />} />
        <Route path="bonuses" element={<Bonuses />} />
        <Route path="settings" element={<Settings />} />
      </Route>

      {/* Admin routes */}
      <Route
        path="/admin"
        element={
          <AdminRoute>
            <AdminLayout><Navigate to="/admin/users" replace /></AdminLayout>
          </AdminRoute>
        }
      />
      <Route
        path="/admin/users"
        element={
          <AdminRoute>
            <AdminLayout><Users /></AdminLayout>
          </AdminRoute>
        }
      />
      <Route
        path="/admin/statistics"
        element={
          <AdminRoute>
            <AdminLayout><Statistics /></AdminLayout>
          </AdminRoute>
        }
      />

    </Routes>
  );
}

function App() {
  return (
    <>
      <ScrollToTop />
      <AuthProvider>
        <AdminAuthProvider>
          <AppRoutes />
          <Payment />
        </AdminAuthProvider>
      </AuthProvider>
    </>
  );
}

export default App; 