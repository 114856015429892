import React, { useState, useEffect } from 'react';
import { UserCircleIcon, InformationCircleIcon } from '@heroicons/react/24/solid';

function Settings() {
  const [userData, setUserData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Функция форматирования даты
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const months = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];

    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()} года`;
  };

  // Загрузка данных пользователя
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch("https://api.king-academy.net/user", {
          method: "GET",
          headers: {
            "token": JSON.parse(token)
          }
        });

        const data = await response.json();
        if (data.status === 200) {
          setUserData(data.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  // const handleAvatarUpload = async (event) => {
  //   const file = event.target.files[0];
  //   if (!file) return;

  //   // Проверка типа файла
  //   if (!file.type.startsWith('image/')) {
  //     setError('Пожалуйста, загрузите изображение');
  //     return;
  //   }

  //   // Проверка размера файла (не более 5MB)
  //   if (file.size > 5 * 1024 * 1024) {
  //     setError('Размер файла не должен превышать 5MB');
  //     return;
  //   }

  //   setIsUploading(true);
  //   setError('');
  //   setSuccess('');

  //   const formData = new FormData();
  //   formData.append('avatar', file);

  //   try {
  //     const token = JSON.parse(localStorage.getItem('token'));
  //     const response = await fetch('https://api.king-academy.net/user/image', {
  //       method: 'POST',
  //       headers: {
  //         'token': token
  //       },
  //       body: formData
  //     });

  //     const data = await response.json();

  //     if (data.status === 200) {
  //       setSuccess('Аватар успешно обновлен');
  //       // Перезагружаем страницу после успешной загрузки
  //       window.location.reload();
  //     } else {
  //       setError(data.message || 'Ошибка при загрузке аватара');
  //     }
  //   } catch (error) {
  //     setError('Произошла ошибка при загрузке аватара');
  //     console.error('Error uploading avatar:', error);

  //   } finally {
  //     setIsUploading(false);
  //   }
  // };

  return (
    <div className="">

      <div className="bg-white rounded-3xl md:p-8 p-4 shadow-sm">

        <div className="flex md:flex-row flex-col md:items-center md:pb-8 pb-4 justify-between border-b border-gray-200">
          <h1 className="text-xl font-bold ">Информация об аккаунте</h1>
          <div className="flex items-center mt-4 md:mt-0 space-x-2">
            <InformationCircleIcon className="w-4 h-4 text-primary" />
            <p className="text-gray-500 text-[12px]">Аккаунт создан {formatDate(userData?.created_at)}</p>
          </div>
        </div>

        <div className="flex items-start space-x-8 md:mt-8 mt-4">
          {/* Аватар */}
          {/* <div className="flex flex-col items-center space-y-4">
            <div className="relative w-32 h-32">
              {userData?.avatar ? (
                <img
                  src={userData.avatar}
                  alt="Аватар пользователя"
                  className="w-full h-full object-cover rounded-full"
                />
              ) : (
                <UserCircleIcon className="w-full h-full text-gray-300" />
              )}

              {isUploading && (
                <div className="absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                </div>
              )}
            </div>

            <label className="relative cursor-pointer">
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleAvatarUpload}
                disabled={isUploading}
              />
              <span className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-xl text-black bg-primary hover:bg-primary/90 transition-colors">
                {isUploading ? 'Загрузка...' : 'Изменить аватар'}
              </span>
            </label>

            <p className="text-[12px] text-center text-gray-500">
              JPG, PNG или GIF <br />
              Максимальный размер 5MB
            </p>
          </div> */}

          {/* Информация о пользователе */}
          <div className="flex-1">

            <div className="space-y-4">
              <div>
                <label className="block text-[12px] font-medium text-gray-500 mb-1">
                  Имя
                </label>
                <p className="text-gray-900 font-medium">{userData?.username || 'Не указано'}</p>
              </div>

              <div>
                <label className="block text-[12px] font-medium text-gray-500 mb-1">
                  Фамилия
                </label>
                <p className="text-gray-900 font-medium">{userData?.lastname || 'Не указано'}</p>
              </div>

              <div>
                <label className="block text-[12px] font-medium text-gray-500 mb-1">
                  Email
                </label>
                <p className="text-gray-900 font-medium">{userData?.email || 'Не указано'}</p>
              </div>

              <div>
                <label className="block text-[12px] font-medium text-gray-500 mb-1">
                  Телефон
                </label>
                <p className="text-gray-900 font-medium">{userData?.contact || 'Не указано'}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Сообщения об ошибках и успехе */}
        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-xl">
            {error}
          </div>
        )}
        {success && (
          <div className="mt-4 p-4 bg-green-50 text-green-700 rounded-xl">
            {success}
          </div>
        )}
      </div>
    </div>
  );
}

export default Settings; 