import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import img1 from '../assets/images/card1.png';
import img2 from '../assets/images/card2.png';
import img3 from '../assets/images/card3.png';
import BottomNav from '../components/mobile/BottomNav';
const COURSE_URLS = {
    silver: 'https://king-academy.online/courses/begginer.html',
    gold: 'https://king-academy.online/courses/medium.html',
    platinum: 'https://king-academy.online/courses/pro.html'
};

function Activate() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const plans = [
        {
            id: 'silver',
            name: 'SILVER',
            price: 100,
            icon: img1,
            description: 'Начальный уровень с базовым функционалом для трейдинга и обучения. Стартовый пакет для новичков.'
        },
        {
            id: 'gold',
            name: 'GOLD',
            price: 250,
            icon: img2,
            description: 'Продвинутый уровень с базовым функционалом для трейдинга и обучения. Стартовый пакет для новичков.',
            isHot: true
        },
        {
            id: 'platinum',
            name: 'PLATINUM',
            price: 500,
            icon: img3,
            description: 'Премиальный уровень с базовым функционалом для трейдинга и обучения. Полный доступ к платформе.'
        }
    ];

    const handlePayment = (plan) => {
        setSearchParams({ course: plan.id, price: plan.price });
    };

    return (
        <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {plans.map((plan) => (
                    <div 
                        key={plan.id}
                        className="bg-white rounded-[20px] p-6 relative"
                    >
                        {plan.isHot && (
                            <div className="absolute -top-2 right-4 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                                Популярный 🔥
                            </div>
                        )}
                        
                        <div className="flex justify-between items-start mb-4">
                            <div>
                                <h3 className="text-gray-400 mb-8 text-sm">{plan.name}</h3>
                                <div className="text-3xl font-bold mt-1">${plan.price}</div>
                            </div>
                            <div className="w-24 h-24 flex items-center justify-center">
                                <img src={plan.icon} alt={plan.name} className="w-full h-full object-contain" />
                            </div>
                        </div>

                        <p className="text-sm text-gray-600 mb-6 min-h-[60px]">
                            {plan.description}
                        </p>

                        <div className="grid grid-cols-2 gap-2">
                            <button
                                onClick={() => handlePayment(plan)}
                                className="flex-1 text-[14px] md:text-[18px] bg-primary text-black font-medium py-2 px-4 rounded-full hover:bg-primary/90 transition-colors"
                            >
                                Пополнить
                            </button>
                            <button
                                onClick={() => window.open(COURSE_URLS[plan.id], '_blank')}
                                className="text-gray-600 hover:text-gray-900 transition-colors text-[14px] md:text-[18px]"
                            >
                                Подробнее
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <BottomNav />
        </div>
    );
}

export default Activate; 