import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { XMarkIcon, ArrowLeftIcon, ClipboardIcon, QrCodeIcon, BanknotesIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import p2pIcon from '../assets/images/payment/p2p.png';
// import cryptoIcon from '../assets/images/crypto.svg';
// import cardsIcon from '../assets/images/cards.svg';
// import p2pIcon from '../assets/images/p2p.svg';
// import overdraftIcon from '../assets/images/overdraft.svg';
import img1 from '../assets/images/card1.png'; // для gold
import img2 from '../assets/images/card2.png'; // для silver
import img3 from '../assets/images/card3.png'; // для platinum
import img4 from '../assets/images/card4.png'; // для остальных

import Card from '../assets/images/payment/card.png';
import Crypto from '../assets/images/payment/crypto.png';

import btc from '../assets/images/payment/btc.svg';
import eth from '../assets/images/payment/eth.svg';
import usdt from '../assets/images/payment/usdt.svg';

import qrUsdt from '../assets/images/payment/usdt.png';
import qrBtc from '../assets/images/payment/btc.png';
import qrEth from '../assets/images/payment/eth.png';
import qrUsdtErc20 from '../assets/images/payment/usdt-erc-20.png';

const WALLET_ADDRESSES = {
  'usdt-trc20': 'TKg9pb15YpvnidQexNFiSAxagFWjzxLyeH',
  'btc': 'bc1qmqwge2xrgne0a9t5gw7tumrg9pkdzg3dx5mull',
  'eth': '0x4b1e34591eED1869f0E11b15ADAe5373aF75c391',
  'usdt-erc20': '0x4b1e34591eED1869f0E11b15ADAe5373aF75c391'
};

const CRYPTO_OPTIONS = [
  { id: 'btc', name: 'Bitcoin (BTC)', icon: btc, qr: qrBtc },
  { id: 'eth', name: 'Ethereum (ETH)', icon: eth, qr: qrEth },
  { id: 'usdt-trc20', name: 'USDT TRC20', icon: usdt, qr: qrUsdt },
  { id: 'usdt-erc20', name: 'USDT ERC20', icon: usdt, qr: qrUsdtErc20 }
];

const PAYMENT_METHODS = [
  {
    id: 'card',
    title: 'Card',
    icon: Card,
    description: 'Visa, Mastercard, Maestro, Мир',
    disabled: false
  },
  {
    id: 'crypto',
    title: 'Crypto',
    icon: Crypto,
    description: 'Bitcoin, Ethereum, USDT, TRX',
    disabled: false
  },
  {
    id: 'p2p',
    title: 'P2P/IBAN',
    icon: p2pIcon,
    description: 'По запросу менеджера',
    disabled: false
  }
];

function Payment() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [copiedWallet, setCopiedWallet] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isP2PLoading, setIsP2PLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch("https://api.king-academy.net/user", {
          method: "GET",
          headers: {
            "token": JSON.parse(token)
          }
        });

        const data = await response.json();
        if (data.status === 200) {
          setUserData(data.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const courseType = searchParams.get('course')?.toUpperCase();
  const price = searchParams.get('price');

  // Функция для определения изображения курса
  const getCourseImage = (type) => {
    switch (type) {
      case 'SILVER':
        return img1;
      case 'GOLD':
        return img2;
      case 'PLATINUM':
        return img3;
      case 'TOPUP':
      default:
        return img4;
    }
  };

  useEffect(() => {
    if (courseType && price) {
      setIsVisible(true);
      document.body.style.overflow = 'hidden';
    } else {
      setIsVisible(false);
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [courseType, price]);

  const handleBack = () => {
    if (selectedCrypto) {
      setSelectedCrypto(null);
      setShowQR(false);
    } else if (selectedMethod) {
      setSelectedMethod(null);
    } else {
      setSearchParams({});
      setIsVisible(false);
    }
  };

  const copyToClipboard = async (text, cryptoId) => {
    try {
      // Современный метод
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
        setCopiedWallet(cryptoId);
        setTimeout(() => setCopiedWallet(null), 2000);
        return;
      }

      // Fallback для старых браузеров и HTTP
      const textArea = document.createElement('textarea');
      textArea.value = text;
      
      // Делаем элемент невидимым
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      
      // Выделяем и копируем текст
      textArea.focus();
      textArea.select();
      
      try {
        document.execCommand('copy');
        setCopiedWallet(cryptoId);
        setTimeout(() => setCopiedWallet(null), 2000);
      } catch (err) {
        console.error('Fallback: Не удалось скопировать', err);
      }
      
      // Удаляем временный элемент
      document.body.removeChild(textArea);
    } catch (err) {
      console.error('Не удалось скопировать текст: ', err);
    }
  };

  const handleStripePayment = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.king-academy.net/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          course: courseType,
          userId: userData.id
        })
      });

      if (response.status === 303) {
        const data = await response.json();
        window.location.href = data.url;
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderStripeForm = () => (
    <button
      onClick={handleStripePayment}
      className="w-full bg-primary text-black font-medium py-3 px-4 rounded-xl hover:bg-primary/90 transition-colors"
    >
      Оплатить через Stripe
    </button>
  );

  const renderCryptoPayment = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
        {CRYPTO_OPTIONS.map((crypto) => (
          <div key={crypto.id} className="border rounded-xl p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src={crypto.icon} alt={crypto.name} className="w-8 h-8 mr-4" />
                <span className="font-medium">{crypto.name}</span>
              </div>
              <button
                onClick={() => setShowQR(crypto.id)}
                className="md:hidden text-primary hover:text-primary/80"
              >
                <QrCodeIcon className="w-6 h-6" />
              </button>
            </div>

            <div className="flex flex-col space-y-2">
              {/* Адрес кошелька */}
              <div className="relative flex items-center bg-gray-50 rounded-lg p-3 break-all">
                <div className="flex-1 pr-10 text-sm md:text-base overflow-x-auto">
                  {WALLET_ADDRESSES[crypto.id]}
                </div>
                {/* Кнопка копирования - теперь абсолютно позиционирована */}
                <button
                  onClick={() => copyToClipboard(WALLET_ADDRESSES[crypto.id], crypto.id)}
                  className="absolute right-2 top-1/2 -translate-y-1/2 p-2 
                            text-primary hover:text-primary/80 
                            active:scale-95 transition-transform"
                >
                  {copiedWallet === crypto.id ? (
                    <CheckIcon className="w-5 h-5" />
                  ) : (
                    <ClipboardIcon className="w-5 h-5" />
                  )}
                </button>
              </div>
            </div>

            {/* QR код для мобильных устройств */}
            {showQR === crypto.id && (
              <div className="mt-4 flex justify-center md:hidden">
                <img src={crypto.qr} alt="QR Code" className="w-48 h-48" />
              </div>
            )}

            {/* QR код для десктопа - всегда виден */}
            <div className="mt-4 justify-center hidden md:flex">
              <img src={crypto.qr} alt="QR Code" className="w-48 h-48" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  if (location.pathname === '/checkout') return null;

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 bg-white overflow-y-auto">
      <div className="max-w-7xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="py-6 flex items-center space-x-4">
          <button
            onClick={handleBack}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            <span>Назад</span>
          </button>
          <div className="text-black font-medium">{userData?.username} {userData?.lastname}</div>
        </div>

        {/* Course Info */}
        <div className="bg-gray-50 rounded-2xl p-6 mb-8">
          <div className="flex items-center md:justify-between md:flex-row flex-col">
            <div className="flex gap-6">
              <div>
                <div className="text-sm text-gray-600 mb-2">Товар</div>
                <div className="text-xl md:text-3xl font-bold">{courseType?.toUpperCase()}</div>
              </div>
              <div>
                <div className="text-sm text-gray-600 mb-2">К оплате</div>
                <div className="text-xl md:text-3xl font-bold">${price}</div>
              </div>
            </div>
            <div className="flex-shrink-0">
              <img
                src={getCourseImage(courseType)}
                alt={`${courseType} course`}
                className="w-24 h-24 object-contain"
              />
            </div>
          </div>
          {/* <div className="mt-4 text-sm text-gray-600">
            Научитесь работать с биржей, использовать базовые инструменты для
            прогнозирования стоимости и торговать самостоятельно с нуля.
          </div> */}
        </div>

        {/* Payment Methods */}
        <div>
          <h2 className="md:text-2xl text-lg font-bold mb-6">
            {selectedMethod === 'crypto' && selectedCrypto 
              ? 'Оплата криптовалютой' 
              : 'Выберите способ оплаты'}
          </h2>
          
          <div className={`grid grid-cols-1 ${!selectedMethod ? 'md:grid-cols-3' : 'md:grid-cols-1'} gap-6`}>
            {!selectedMethod && PAYMENT_METHODS.map((method) => (
              method.id === 'card' ? (
                <form 
                  key={method.id}
                  method='POST' 
                  action='https://api.king-academy.net/create-checkout-session'
                  className="w-full"
                >
                  <input type="hidden" name="course" value={courseType} />
                  <input type="hidden" name="price" value={price} />
                  <button
                    type="submit"
                    className="w-full flex flex-col md:items-center p-6 rounded-2xl border border-gray-200 hover:border-primary/50 transition-all duration-200"
                  >
                    <div className="flex-shrink-0 w-12 h-12 md:w-32 md:h-32 md:mb-4">
                      <img src={method.icon} alt={method.title} className="w-full h-full object-contain" />
                    </div>
                    <div className="text-left md:text-center">
                      <h3 className="font-medium text-gray-900">{method.title}</h3>
                      <p className="text-sm text-gray-500">{method.description}</p>
                    </div>
                  </button>
                </form>
              ) : (
                <button
                  key={method.id}
                  onClick={() => {
                    if (method.id === 'p2p') {
                      setIsP2PLoading(true);
                    } else {
                      setSelectedMethod(method.id);
                    }
                  }}
                  className="w-full flex flex-col md:items-center p-6 rounded-2xl border border-gray-200 hover:border-primary/50 transition-all duration-200"
                  disabled={method.id === 'p2p' && isP2PLoading}
                >
                  <div className="flex-shrink-0 w-12 h-12 md:w-32 md:h-32 md:mb-4">
                    {method.id === 'p2p' && isP2PLoading ? (
                      <div className="animate-spin rounded-full h-full w-full border-b-2 border-primary"></div>
                    ) : typeof method.icon === 'function' ? (
                      <method.icon className="w-full h-full text-gray-600" />
                    ) : (
                      <img src={method.icon} alt={method.title} className="w-full h-full object-contain" />
                    )}
                  </div>
                  <div className="text-left md:text-center">
                    <h3 className="font-medium text-gray-900">{method.title}</h3>
                    <p className="text-sm text-gray-500">{method.description}</p>
                  </div>
                </button>
              )
            ))}

            {selectedMethod === 'card' && renderStripeForm()}
            {selectedMethod === 'crypto' && renderCryptoPayment()}
            {selectedMethod === 'p2p' && (
              <div className="text-center text-gray-600">
                Для оплаты через P2P/IBAN, пожалуйста, свяжитесь с нашим менеджером
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment; 