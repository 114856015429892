import { Link, useNavigate } from 'react-router-dom';
import { ChevronRightIcon, UserCircleIcon, Cog6ToothIcon, ArrowRightOnRectangleIcon, HomeIcon, EllipsisHorizontalIcon, XMarkIcon, PowerIcon, AcademicCapIcon, CalendarIcon, ChartBarIcon, GiftIcon, UserIcon, CreditCardIcon, ChatBubbleLeftIcon, VideoCameraIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useState, useRef, useEffect } from 'react';
import logo from '../assets/images/logo.svg';
import { useAuth } from '../contexts/AuthContext';

function PageTitle({ title, breadcrumbs }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const { logout } = useAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            if (!token) return;

            try {
                const response = await fetch("https://api.king-academy.net/user", {
                    method: "GET",
                    headers: {
                        "token": JSON.parse(token)
                    }
                });

                const data = await response.json();
                if (data.status === 200) {
                    setUserData(data.data);
                    console.log('User data:', data.data);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = async () => {
        try {
            await logout();
            setIsMenuOpen(false);
            navigate('/login');
        } catch (error) {
            console.error('Ошибка при выходе:', error);
        }
    };

    const getDisplayStatus = () => {
        if (!userData) return 'Загрузка...';

        if (userData.course && userData.course !== "NONE") {
            return userData.course;
        }

        if (userData.overdraft) {
            return "OVERDRAFT";
        }

        return "Нет курса";
    };

    const getInitials = (firstName, lastName) => {
        const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '';
        const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
        return `${firstInitial}${lastInitial}`;
    };

    const menuItems = [
        {
            title: 'Основное',
            items: [
                { name: 'Главная', icon: HomeIcon, path: '/' },
                { name: 'Профиль', icon: UserIcon, path: '/settings' },
                { name: 'Активировать аккаунт', icon: PowerIcon, path: '/activate' },

                // { name: 'Пополнить баланс', icon: CreditCardIcon, path: '/topup' },
            ]
        },
        {
            title: 'Обучение',
            items: [
                { name: 'Книги', icon: BookOpenIcon, path: '/education/books' },
                { name: 'Видеоуроки', icon: VideoCameraIcon, path: '/education/video' },
                { name: 'Календарь', icon: CalendarIcon, path: '/calendar' },
                { name: 'Трейдинг центр', icon: ChartBarIcon, path: '/trading' },
            ]
        },
        {
            title: 'Дополнительно',
            items: [
                { name: 'Бонусы', icon: GiftIcon, path: '/bonuses' },
                // { name: 'Поддержка', icon: ChatBubbleLeftIcon, path: '/support' },
                { 
                    name: 'Выйти', 
                    icon: ArrowRightOnRectangleIcon, 
                    onClick: handleLogout,
                    className: 'text-red-500'
                },
            ]
        },
    ];

    return (
        <div className="mb-4 md:mb-8">
            {/* Header */}
            <div className="flex border-b border-[#98A8B1] pb-4 items-center w-full md:justify-end">
                <div className="flex items-center justify-between md:justify-end w-full md:space-x-4 space-x-2">
                    {/* Logo mobile */}
                    <a href="/">
                        <img src={logo} alt="logo" className="h-8 md:hidden" />
                    </a>

                    {/* Desktop menu Balance */}
                    <div className="md:block hidden">
                        <div className="text-[12px] text-gray-400">Баланс</div>
                        <div className="text-[16px] font-bold">{userData?.balance || '0.00'}$</div>
                    </div>

                    {/* Desktop menu Course */}
                    <div className="md:block hidden">
                        <div className="text-[12px] text-gray-400">Курс</div>
                        <div className="text-[16px] font-bold">{getDisplayStatus()}</div>
                    </div>

                    <div className="flex items-center space-x-2">
                        {/* Desktop menu Account */}
                        <div className="relative md:border-l border-[#98A8B1] pl-4">
                            <button
                                ref={buttonRef}
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                className="flex items-center space-x-2 hover:opacity-80 transition-opacity"
                            >
                                <div className="md:flex hidden flex-col justify-start text-end">
                                    <div className="text-[12px] text-gray-400">Аккаунт</div>
                                    <div className="text-[16px] font-bold">
                                        {userData ? `${userData.username} ${userData.lastname}` : 'Загрузка...'}
                                    </div>
                                </div>
                                {userData ? (
                                    <div className="h-10 w-10 rounded-full md:bg-primary bg-white flex items-center justify-center text-black font-medium shadow-md">
                                        {getInitials(userData.username, userData.lastname)}
                                    </div>
                                ) : (
                                    <UserCircleIcon className="h-10 w-10 bg-white text-gray-300 rounded-full p-1 shadow-md" />
                                )}
                            </button>

                            {isDropdownOpen && (
                                <div
                                    ref={dropdownRef}
                                    className="absolute right-0 mt-2 w-48 bg-white rounded-[20px] shadow-lg py-2 z-50"
                                >
                                    <div className="px-4 py-2">
                                        <Link
                                            to="/settings"
                                            className="flex items-center space-x-3 text-[#98A8B1] hover:text-black transition-colors py-2"
                                        >
                                            <div className="bg-primary p-2 rounded-full">
                                                <Cog6ToothIcon className="h-5 w-5 text-[#B17A00]" />
                                            </div>
                                            <span className="text-[14px]">Настройки</span>
                                        </Link>
                                        <button
                                            onClick={handleLogout}
                                            className="flex items-center space-x-3 text-[#98A8B1] hover:text-black transition-colors py-2 w-full"
                                        >
                                            <div className="bg-primary p-2 rounded-full">
                                                <ArrowRightOnRectangleIcon className="h-5 w-5 text-[#B17A00]" />
                                            </div>
                                            <span className="text-[14px]">Выйти</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Mobile menu */}
                        <nav className="flex bg-white rounded-full shadow-md p-2 items-center md:hidden space-x-4">
                            <button 
                                onClick={() => setIsMenuOpen(true)}
                                className="flex items-center justify-center w-8 h-8"
                            >
                                <EllipsisHorizontalIcon className="h-6 w-6 text-black" />
                            </button>
                        </nav>
                    </div>

                </div>
            </div>

            {/* Breadcrumbs */}
            <div className="flex items-center mt-8 space-x-2 text-sm text-gray-400 mb-2">
                {breadcrumbs.map((crumb, index) => (
                    <div key={crumb.path} className="flex items-center md:text-sm text-[12px]">
                        {index > 0 && <ChevronRightIcon className="md:h-4 h-3 md:w-4 w-3 md:mx-1 mx-0 md:mr-2 mr-2" />}
                        <Link
                            to={crumb.path}
                            className={index === breadcrumbs.length - 1 ? 'text-primary' : 'hover:text-primary'}
                        >
                            {crumb.text}
                        </Link>
                    </div>
                ))}
            </div>
            <h1 className="md:text-2xl text-xl font-bold">{title}</h1>

            {/* Alert */}
            {userData && userData.course === "NONE" && (
                <div className="bg-[#F2E8E9] mt-4 rounded-xl md:px-6 px-4 py-4 flex items-center justify-between md:border-none border-2 border-[#D86962]/10">
                    <div className="flex items-center text-[#D86962]">
                        <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
                        <span className="text-[14px] font-medium">Требуется активация аккаунта</span>
                    </div>
                    <Link
                        to="/activate"
                        className="px-4 py-2 md:block hidden  bg-[#D86962] text-white rounded-full transition-colors hover:bg-[#c55c55]"
                    >
                        Активировать
                    </Link>
                </div>
            )}

            {/* Мобильное меню */}
            <div className={`fixed inset-y-0 right-0 w-[80%] max-w-sm bg-gradient-to-b from-[#1C1C1E] to-[#2C2C2E] 
                          transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
                          transition-transform duration-300 ease-in-out z-50
                          flex flex-col`}>
                {/* Шапка меню */}
                <div className="flex items-center justify-between p-6 border-b border-white/10">
                    <h2 className="text-white font-medium">Меню</h2>
                    <button 
                        onClick={() => setIsMenuOpen(false)}
                        className="p-2 hover:bg-white/5 rounded-full transition-colors"
                    >
                        <XMarkIcon className="h-6 w-6 text-white" />
                    </button>
                </div>

                {/* Контент меню */}
                <div className="flex-1 overflow-y-auto py-6 px-4 space-y-8">
                    {menuItems.map((section, idx) => (
                        <div key={idx} className="space-y-4">
                            <h3 className="text-white/50 text-sm px-2">{section.title}</h3>
                            <div className="space-y-1">
                                {section.items.map((item, itemIdx) => (
                                    item.onClick ? (
                                        <button
                                            key={itemIdx}
                                            onClick={item.onClick}
                                            className={`flex items-center space-x-3 p-2 rounded-xl w-full
                                                     text-white hover:bg-white/5 transition-colors
                                                     active:bg-white/10 ${item.className || ''}`}
                                        >
                                            <div className="bg-primary/10 p-2 rounded-lg">
                                                <item.icon className="h-5 w-5 text-[#FFB800]" />
                                            </div>
                                            <span className="font-medium">{item.name}</span>
                                        </button>
                                    ) : (
                                        <Link
                                            key={itemIdx}
                                            to={item.path}
                                            onClick={() => setIsMenuOpen(false)}
                                            className="flex items-center space-x-3 p-2 rounded-xl
                                                     text-white hover:bg-white/5 transition-colors
                                                     active:bg-white/10"
                                        >
                                            <div className="bg-primary/10 p-2 rounded-lg">
                                                <item.icon className="h-5 w-5 text-[#FFB800]" />
                                            </div>
                                            <span className="font-medium">{item.name}</span>
                                        </Link>
                                    )
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Футер меню */}
                <div className="p-6 border-t border-white/10">
                    <div className="text-white/50 text-sm text-center">
                        King Academy
                    </div>
                </div>
            </div>

            {/* Оверлей */}
            {isMenuOpen && (
                <div 
                    className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40"
                    onClick={() => setIsMenuOpen(false)}
                />
            )}
        </div>
    );
}

export default PageTitle; 